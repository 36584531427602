<template>
<div>
  <div id="main" class="site-main">

<div id="main-content" class="main-content">


<div id="page-title" class="page-title-block page-title-style-1 has-background-image" style="background-image: url(/assets/backgrounds/uroki-v-gorodah/18.jpg);"><div class="container"><div class="page-title-title"><h1 style="">  Уроки в городах России</h1></div></div></div>




<div class="block-content">
	<div class="container">
		<div class="panel row">

			<div class="panel-center col-xs-12">
				<article id="post-9629" class="post-9629 page type-page status-publish hentry">


					<div class="entry-content post-content">
												<div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner "><div class="wpb_wrapper"><div class="fullwidth-block clearfix styled-marker-top" style="background-color: #ffffff;background-image: url(/assets/backgrounds/uroki-v-gorodah/peremena.jpg);background-repeat: no-repeat; background-size: cover;background-position: center top;"><div class="container"><div class="clearboth"></div><div class="sc-divider" style="margin-bottom: 100px;"></div><div class="centered-box"><div class="sc-icon sc-icon-size-big  sc-icon-shape-square sc-simple-icon" style=""><span class="sc-icon-half-1" style="color: #ffffff;"><span class="back-angle">&#xe7db;</span></span><span class="sc-icon-half-2" style="color: #ffffff;"><span class="back-angle">&#xe7db;</span></span></div></div>
	<div class="wpb_text_column wpb_content_element " >
		<div class="wpb_wrapper">
			<h1 style="text-align: center;"><span style="color: #ffffff;">Уроки в городах России</span></h1>
<div class="styled-subtitle" style="text-align: center;">
<span style="color: #ffffff; font-size: 20px;"><p>Создание единого образовательного пространства для обучающихся детей на основе объединения учреждений культуры и искусства.</p>
<p>Создание системы непрерывного образования детей в условиях развития программ внутреннего туризма на территории Российской Федерации совместно с органами власти, представителями системы образования, туриндустрии, бизнеса.</p>
<p>Формирование целевых ориентиров у воспитанников и обучающихся, которые включают семейные, духовно-нравственные, гражданско-патриотические ценности. Создание условий для развития индивидуальной траектории воспитанников и обучающихся. Развитие коммуникативной и социальной адаптации воспитанников и обучающихся в условиях развития программ дополнительного образования на базе программ социально-образовательного туризма.<br />
Создание условий для реализации проекта, которые требуют наличие программ и методических рекомендаций, соответствующих требованиям заказчика к туристическим и образовательным услугам.</p></span></div>

		</div>
	</div>
<div class="centered-box"><a class="sc-button "  href="http://www.peremena.travel/" target="_self" style="background-color: #b5cb5c;">Сайт проекта</a></div><div class="clearboth"></div><div class="sc-divider" style="margin-bottom: 213px;"></div></div></div></div></div></div></div></p>
					</div><!-- .entry-content -->







				</article><!-- #post-## -->

			</div>


		</div>


	</div>
</div>



</div><!-- #main-content -->
</div>
</div>
</template>

<script>
import {rollup} from "@/lib/utils.js"
export default {
name : 'Projects_uroki_v_gorodah',
mounted() {

  rollup()
}


}


</script>
